import React, { useState } from "react";
import bgimage from "../Assets/bg.png";
import lowerbg from "../Assets/lowerBg.png";
import { IoMdSearch } from "react-icons/io";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Link } from "react-router-dom";

const CNSCoop = () => {
  const data = [
    {
      name: "Bocadillos y Canapes",
      text: "Monterrey, N.L.",
      img: require("../Images/Intra/l2.png"),
      bg: require("../Images/Intra/I1.png"),
    },
    {
      name: "Alimentus",
      text: "Monterrey, N.L.",
      img: require("../Images/Intra/l2.png"),
      bg: require("../Images/Intra/I1.png"),
    },
    {
      name: "Marlene Portillo Eventos",
      text: "Monterrey, N.L.",
      img: require("../Images/Intra/l2.png"),
      bg: require("../Images/Intra/I1.png"),
    },
    {
      name: "Bocadillos y Canapes",
      text: "Monterrey, N.L.",
      img: require("../Images/Intra/l2.png"),
      bg: require("../Images/Intra/I1.png"),
    },
    {
      name: "Alimentus",
      text: "Monterrey, N.L.",
      img: require("../Images/Intra/l2.png"),
      bg: require("../Images/Intra/I1.png"),
    },
    {
      name: "Marlene Portillo Eventos",
      text: "Monterrey, N.L.",
      img: require("../Images/Intra/l2.png"),
      bg: require("../Images/Intra/I1.png"),
    },
  ];

  const items = ["Ambos", "Bienes", "Servicios"]; // Sample items for the list

  const [modalShow, setModalShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for search input

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top smoothly
  };

  // Filter the data based on the search query
  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      {/* <Header /> */}
      <div className="flex relative flex-col w-full bg-[url('./Images/workBg.png')] bg-cover bg-no-repeat">
      <div className="mx-auto mt-32 flex items-center justify-center gap-4 sm:gap-10">
          <img
            src={require("../Assets/triangle.png")}
            className="h-9 w-9 sm:h-16 sm:w-16"
            alt=""
          />
          <div className="text-md font-bold leading-[30px] text-[#71A850] sm:text-2xl sm:leading-[47px] md:text-4xl">
            Provedoores
          </div>
        </div>
        <div className="w-full min-h-[1000px] lg:min-h-[1144px] pt-[10%] flex flex-col  relative  mb-[70px]">
          {/* <img
            src={require("../Assets/lowerBg.png")}
            className="absolute w-full h-full top-0 "
            alt=""
          /> */}
          <div className="max-w-[1250px] z-10 mx-auto  flex flex-col gap-[20px] w-full  md:flex-row justify-between items-center ">
            <div className="w-[287px] h-[57px]  flex justify-center items-center px-[20px] bg-[#D9D9D9] rounded-[12px]">
              <input
                type="text"
                name=""
                id=""
                placeholder="Buscar Proveedor..."
                className="outline-none border-none  bg-transparent"
                value={searchQuery} // Bind the input to the state
                onChange={(e) => setSearchQuery(e.target.value)} // Update the state when input changes
              />
              <img src={require("../Images/Intra/search.png")} alt="" />
            </div>
            <div className="p-4  bg-[#D9D9D9] rounded-[12px]">
              <label className="font-bold text-[22px] text-[#828282] leading-[26px]">
                Proveedor de:
              </label>
              <ul className="list-disc mt-[10px] pl-7">
                {items.map((item, index) => (
                  <li
                    key={index}
                    className="font-bold mt-[5px] text-[#4A4F54] fontClass text-[16px] leading-[18px] "
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="max-w-[1250px]  z-20 mx-auto mt-[40px] gap-[30px] xl:gap-[0px]  px-[20px] xl:px-[0px] w-full flex flex-col lg:flex-row lg:justify-between items-center flex-wrap ">
            {filteredData.map((item, index) => {
              return (
                <div
                  className={`flex flex-col flex-start max-w-[380px]  ${
                    index > 2 ? "mt-[20px]  sm:mt-[100px]" : "mt-[20px] sm:mt-[0px]"
                  } w-full `}
                >
                  <div
                    className="flex  w-full rounded-xl  relative justify-between items-start pt-[50px] pl-[20px] pr-[30px] max-w-[380px]"
                    style={{
                      backgroundImage: `url(${item.bg})`,
                      backgroundSize: "cover", // Make the background cover the entire div
                      backgroundPosition: "center", // Center the background image
                      height: "296px", // Set a specific height for the div
                      width: "100%", // Set the width to 100%
                    }}
                  >
                    <div>
                      <div className="font-bold text-[20px] leading-[26px] fontClass text-white">
                        {item.name}
                      </div>
                      <div className="text-[15px] leading-[17px] fontClass text-white">
                        {item.text}
                      </div>
                    </div>
                    <div className="bg-white rounded-[12px] p-[5px]">
                      <img src={item.img} alt="" />
                    </div>
                    <div className="absolute left-[20px] bottom-[30px]">
                      <Link to={"/system/validation"} onClick={handleLinkClick}>
                        <img src={require("../Assets/Default.png")} alt="" />
                      </Link>
                    </div>
                    
                    <div className="absolute right-[100px] bottom-[-30px] box">
                    <Link to={"/system/financepage"} onClick={handleLinkClick}>

                      <img src={require("../Images/Intra/circle.png")} alt="" />
                      </Link>

                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default CNSCoop;
