import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import styles from './ScoreReport.module.css'
import Table from 'react-bootstrap/Table';
import { IoReload } from "react-icons/io5";
import { IoIosStar } from "react-icons/io";
import { IoIosStarOutline } from "react-icons/io";
import { TfiReload } from "react-icons/tfi";
const ScoreReport = () => {
    const [key, setKey] = useState('Puntaje');
  return (
    <div className={styles.scoreReportMain}>
       <div className={styles.scoreContainer}>
      <h1 className={styles.reportHeadingMain}>Reportes</h1>

      <div className={styles.tabContainer}>
      <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="Puntaje" title="Puntaje">
      <div className={styles.summaryContainer}>
      <div className={styles.cardsMain}>
  <div className={styles.cardsContainer}>
  <IoReload  className={styles.rankIcn}/>
  <div className={styles.cardTextMain}>
<div className={styles.cardText1}>
  19628
</div>
<div className={styles.cardText2}>
En circulación
</div>
  </div>
  </div>
  <div className={styles.cardsContainer2}>
  <IoIosStar className={styles.ArrowUpIcn}/>
  <div className={styles.cardTextMain}>
<div className={styles.cardText1}>
  3588
</div>
<div className={styles.cardText3}>
Otorgados
</div>
  </div>
  </div>
  <div className={styles.cardsContainer3}>
  <IoIosStarOutline  className={styles.ArrowDownIcn}/>
  <div className={styles.cardTextMain}>
<div className={styles.cardText1}>
1280
</div>
<div className={styles.cardText4}>
Descontados
</div>
  </div>
  </div>
  <div className={styles.cardsContainer4}>
  <TfiReload  className={styles.reloadIcn}/>
  <div className={styles.cardTextMain}>
<div className={styles.cardText1}>
820
</div>
<div className={styles.cardText5}>
Usados
</div>
  </div>
  </div>
  </div>
      </div>
      </Tab>
      <Tab eventKey="Logros" title="Logros" disabled>
        Tab content for Profile
      </Tab>
      <Tab eventKey="Rangos" title="Rangos" disabled>
        Tab content for Contact
      </Tab>
    </Tabs>

      </div>

    <div className={styles.tableMain1}>
    <div className={styles.spacetable}></div>

      <Table responsive className={styles.tableMain2}>
        
      <thead>
        <tr>
          <th className={styles.tableHeadings}>Tipos de puntos</th>
          <th className={styles.tableHeadings}>En circulación</th>
          <th className={styles.tableHeadings}>Otorgados</th>
          <th className={styles.tableHeadings}>Descontados</th>
          <th className={styles.tableHeadings}>Usados</th>
        
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={styles.tableTextTwo}>Puntaje</td>
          <td className={styles.tableTextTwo}>400</td>
          <td className={styles.tableTextTwo}>400</td>
          <td className={styles.tableTextTwo}>0</td>
          <td className={styles.tableTextTwo}>0</td>
         
        </tr>
        <tr>
        <td className={styles.tableTextTwo}>Puntaje</td>
          <td className={styles.tableTextTwo}>1979</td>
          <td className={styles.tableTextTwo}>1639</td>
          <td className={styles.tableTextTwo}>10</td>
          <td className={styles.tableTextTwo}>0</td>
         
        </tr>
        <tr>
        <td className={styles.tableTextTwo}>Puntaje</td>
          <td className={styles.tableTextTwo}>6770</td>
          <td className={styles.tableTextTwo}>510</td>
          <td className={styles.tableTextTwo}>160</td>
          <td className={styles.tableTextTwo}>220</td>
        
        </tr>
        <tr>
        <td className={styles.tableTextTwo}>Puntaje</td>
          <td className={styles.tableTextTwo}>10479</td>
          <td className={styles.tableTextTwo}>1039</td>
          <td className={styles.tableTextTwo}>1110</td>
          <td className={styles.tableTextTwo}>600</td>
        
        </tr>
      </tbody>
    </Table>
    <div className={styles.spacetable}></div>
    
    </div>

    </div>
    </div>
  )
}

export default ScoreReport
