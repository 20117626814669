import React from "react";
import styles from "./Terms.module.css";
import Term from "../../Images/Work2.png";

const Terms = () => {
  return (
    <div>
      {/* <Header/> */}
      <div className={styles.termsMain}>
        <div className={styles.termsContainer}>
          <div className={styles.termsMainHeading}>
            <img src={Term} alt="" className={styles.TermIcnImg} />
            Términos y condiciones
          </div>
          <div className={styles.termsHeadingText}>
            <br />
            El “USUARIO”, entendiéndose a éste como aquella persona que mediante
            un identificador de usuario y una contraseña, realiza el uso o
            acceso a través de un equipo de cómputo, dispositivo móvil y/o de
            comunicación debiendo ser conseguido y costeado por él mismo, además
            de que asumirá y deberá de responder en forma exclusiva por todos
            los costos y gastos que se generen por el uso de dicho dispositivo
            por su mantenimiento, así como por cualquier tipo de daño que
            pudiera sucederles, por el acceso a la plataforma de licitaciones
            (En lo sucesivo "PORTAL") de CSN COOPERATIVA FINANCIERA S.C. de A.P.
            de R.L. de C.V. (En adelante "CSN").
            <br />
            <br />
            Derivado de lo anterior, por este medio está de acuerdo en los usos,
            buenas costumbres, servicios, términos, privacidad y condiciones que
            más adelante se detallan y deberá cumplir con éstos en todo tiempo,
            por el uso y acceso al "PORTAL" cuando corresponda, por lo que
            acepta y se obliga a su cumplimiento mediante la presente y/o su
            acceso del "PORTAL". A todo el presente acuerdo se le podrá
            denominar "Términos y Usos".
            <br />
            <br />
            En caso de que el "USUARIO ", no esté de acuerdo con lo aquí
            establecido, deberá de abstenerse de acceder o utilizar el "PORTAL".
            El "USUARIO" conviene en no utilizar dispositivos, software, o
            cualquier otro medio tendiente a interferir tanto en las actividades
            y/u operaciones del "PORTAL" o en las bases de datos y/o información
            que se contenga en el mismo.
            <br />
            <br />
            CSN se reserva el derecho de aceptar el usuario y contraseña
            solicitados por el " USUARIO ", esto en caso de que los propuestos
            ya coincidan con algún otro establecido con anterioridad por otro
            usuario, o que éstos no cumplan con los caracteres necesarios para
            su creación o que a juicio de CSN no sean apropiados para el uso
            común.
            <br />
            <br />
            El "USUARIO" autoriza remitir la información y/o documentación
            necesaria para la ejecución o funcionamiento del “PORTAL”, así como
            aceptar que desde este momento se encuentran sujetos a la aceptación
            de CSN, por lo que, en cualquier momento CSN podrá requerir la
            información y/o documentación adicionalmente y/o las veces que sean
            necesarias, a fin de acreditar fehacientemente que se cubren con los
            requisitos de registro y/o seguimiento del proceso interno de
            licitaciones dentro del “PORTAL”.
            <br />
            <br />
            <br />
            <span>Acceso y Contenidos.</span>
            <br />
            <br />
            El acceso o utilización del "PORTAL" expresan la adhesión plena y
            sin reservas del "USUARIO" a los presentes "Términos y Condiciones
            de Uso y Privacidad". "CSN" tendrá el derecho a negar, restringir o
            condicionar al "USUARIO" el acceso al "PORTAL", total o
            parcialmente, a su entera discreción, así como a modificar los
            "Servicios y Contenidos" del "PORTAL" en cualquier momento y sin
            necesidad de previo aviso.
            <br />
            <br />
            “CSN” no garantiza la disponibilidad y continuidad de la operación
            del "PORTAL" y de los "Servicios y Contenidos", ni la utilidad del
            "PORTAL" en relación con cualquier actividad específica,
            independientemente del medio de acceso que utilice. CSN no será
            responsable por daño o pérdida alguna de cualquier naturaleza que
            pueda ser causado debido a la falta de disponibilidad o continuidad
            de operación del "PORTAL".
            <br />
            <br />
            El aprovechamiento de los Servicios y Contenidos del "PORTAL" es
            exclusiva responsabilidad del "USUARIO", quien en todo caso deberá
            servirse de ellos acorde a las funcionalidades permitidas en el
            propio "PORTAL" y a los usos autorizados en los presentes "Términos
            y Condiciones de Uso y Privacidad”, por lo que "USUARIO" se obliga a
            utilizarlos de modo tal que no atenten contra las normas de uso y
            convivencia en internet, las leyes de los Estados Unidos Mexicanos y
            la legislación vigente en el país en que "USUARIO" se encuentre al
            usarlos, las buenas costumbres, la dignidad de la persona y los
            derechos de terceros. "El PORTAL" es para el uso individual del
            "USUARIO" por lo que, no podrá comercializar de manera alguna los
            Servicios y Contenidos.
            <br />
            <br />
            <br />
            <span>Obligaciones del USUARIO.</span>
            <br />
            <br />
            El Usuario se obliga a:
            <br />
            a) Mantener vigentes sus cuentas de consultas para el acceso
            regular. b) Proveer a “CSN” un correo electrónico para realizar las
            alertas correspondientes. c) Custodiar su usuario y su contraseña
            para protección de la información de sus cuentas. d) Cambiar la
            contraseña o claves secretas de acceso en línea cada cierto tiempo,
            el cual podrá ser, en su caso, establecido por “CSN”, y se obliga a
            no reutilizar las claves anteriores; El “USUARIO” podrá cambiar las
            mismas, cuando lo estime conveniente, desde el "PORTAL". e) No
            transferir su usuario y contraseña, ni a permitir el uso u obtención
            de los mismos por parte de terceros no autorizados; Lo anterior
            incluye eventos electrónicos que permitan la captura indebida del
            usuario y contraseña.
            <br />
            <br />
            f) Ser el encargado de crear su usuario y de las operaciones que se
            realicen con su clave de acceso.
            <br />
            g) Utilizar los servicios y contenidos del "PORTAL", o cualquier
            medio o canal que se ponga a su disposición única y exclusivamente
            para hacer operaciones lícitas. h) Responder por cualquier daño que
            se ocasione a su patrimonio o al de terceros, eximiendo de toda
            responsabilidad legal a CSN.
            <br />
            i) Eximir de cualquier responsabilidad legar a CSN por cualquier
            daño o perjuicio que pueda ocasionarle el uso del presente servicio,
            contenido o "PORTAL" y/o en su caso, asumir los que fueren causados
            a terceros.
            <br />
            j) El USUARIO debe tener su correo electrónico válido y capturado en
            los sistemas de CSN para recibir notificaciones.
            <br />
            k) Cumplir con las obligaciones y términos del presente.
            <br />
            <br />
            <br />
            <span>Potestades de CSN</span>
            <br />
            <br />
            “CSN” tiene las siguientes potestades:
            <br />
            a) Derecho de investigar toda operación no usual o catalogada como
            sospechosa realizada a través del “PORTAL” para cumplir con las
            leyes y disposiciones de su país. b) Derecho de no aceptar el nombre
            del Usuario. c) Modificar, interrumpir o suspender el horario de
            atención de los servicios contenidos en el presente acuerdo para
            efectuar operaciones de mantenimiento o por cualquier otra
            circunstancia que fuera necesaria a juicio de CSN; Para lo anterior
            CSN podrá notificar al
            <br />
            <br />
            "USUARIO" por los medios electrónicos pactados y los que estime
            conveniente. Exención de Responsabilidad Legal.
            <br />
            <br />
            El “USUARIO” exime de responsabilidad legal a “CSN” por lo
            siguiente: a) Por cualquier causa originada o derivada por descuido,
            pérdida o extravío de las contraseñas o por cualquier otra causa,
            aun cuando las mismas fueren o no imputables a su intención, culpa o
            negligencia del "USUARIO".
            <br />
            b) Por información o instrucciones erradas suministradas por el
            "USUARIO" a CSN. c) Por suspensión del servicio o cualquier otra
            causa, aun cuando sea imputable a CSN. d) Por cualquier caso
            fortuito o fuerza mayor por imprevistos o imprevisibles que éstos
            fueren.
            <br />
            <br />
            <br />
            <span>Restricciones</span>
            <br />
            <br />
            El "USUARIO" no tiene el derecho de colocar híper ligas dentro del
            "PORTAL", ni a utilizar las ligas del "PORTAL" ni el derecho de
            colocar o utilizar los "Servicios y Contenidos" en sitios o páginas
            propias o de terceros sin autorización previa y por escrito de CSN;
            Asimismo, el "USUARIO" no tendrá el derecho de limitar o impedir a
            cualquier otro “USUARIO” de “CSN” el uso del "PORTAL".
            <br />
            <br />
            <br />
            <span>Propiedad Intelectual.</span>
            <br />
            <br />
            Los derechos de propiedad intelectual respecto de los "Servicios y
            Contenidos" y los signos distintivos y dominios de las páginas o el
            "PORTAL", así como los derechos de uso y explotación de los mismos,
            incluyendo su divulgación, publicación, reproducción, distribución y
            transformación, son propiedad exclusiva de “CSN”; El “USUARIO” no
            adquiere ningún derecho de propiedad intelectual por el simple uso o
            acceso de los "Servicios y Contenidos" del "PORTAL" y en ningún
            momento dicho uso será considerado como una autorización o licencia
            para utilizar los "Servicios y Contenidos" con fines distintos a los
            que se contemplan en los presentes Términos y Condiciones de Uso y
            Privacidad.
            <br />
            <br />
            <br />
            <span>Propiedad Intelectual de Terceros.</span>
            <br />
            <br />
            El “USUARIO” acuerda que las disposiciones que se establecen en el
            inciso "Propiedad Intelectual" anterior respecto de las
            titularidades de los derechos de “CSN”, también son aplicables a los
            derechos de terceros respecto de los Servicios y Contenidos de las
            páginas, dominios o información presentada o vinculada al "PORTAL".
            <br />
            <br />
            <br />
            <span>Calidad de los Servicios y Contenidos.</span>
            <br />
            <br />
            Ni “CSN”, ni sus proveedores o Socios, incluidos los comerciales,
            serán responsables de cualquier daño o perjuicio que sufra el
            "USUARIO" a consecuencia de inexactitudes, consultas realizadas,
            asesorías, errores tipográficos y cambios o mejoras que se realicen
            periódicamente a los "Servicios y Contenidos"; Las recomendaciones y
            consejos obtenidos a través del "PORTAL" son de naturaleza general,
            por lo que no deben tomarse en cuenta en la adopción de decisiones
            personales ni profesionales; Para ello se debe consultar a un
            profesional apropiado que pueda asesorar al "USUARIO" de acuerdo con
            sus necesidades específicas.
            <br />
            <br />
            <br />
            <span>Bienes y Servicios de Terceros Enlazados.</span>
            <br />
            <br />
            El hecho de que se ofrezca información en el "PORTAL" o en otros
            ligados o vinculados, no implica la recomendación, garantía,
            patrocinio o aprobación por parte de CSN respecto de dicha
            información, bienes y/o servicios; La disponibilidad de bienes y/o
            servicios ofertados por terceros o por sitios ligados o vinculados,
            no es responsabilidad de “CSN”; En vista de lo anterior, “CSN” no
            será responsable ante cualquier autoridad de cualquier naturaleza,
            por cualquier asunto relacionado con la venta, consumo,
            distribución, entrega, disponibilidad o prestación con respecto de
            cualquiera de los bienes y/o servicios ofertados por terceros o por
            sitios ligados o vinculados a través del "PORTAL".
            <br />
            <br />
            Respecto de los "Servicios y Contenidos" que prestan terceros dentro
            o mediante enlaces al "PORTAL" (tales como ligas, banners, botones u
            otros), “CSN” se limita exclusivamente, para conveniencia del
            "USUARIO", a: (i) Informar al "USUARIO" sobre los mismos, y (ii) a
            proporcionar un medio para poner en contacto al “USUARIO” con las
            licitaciones que “CSN” ofrece; No existe ningún tipo de relación
            laboral, asociación o sociedad, entre “CSN” y el o los “USUARIOS”.
            <br />
            <br />
            <br />
            <span>Confidencialidad</span>
            <br />
            <br />
            “CSN” se obliga a mantener confidencial la información que reciba
            del "USUARIO" que tenga dicho carácter conforme a las disposiciones
            legales aplicables en los Estados Unidos Mexicanos; CSN no asume
            ninguna obligación de mantener confidencial cualquier otra
            información que el "USUARIO" le proporcione, ya sea al inscribirse
            al "PORTAL" o en cualquier otro momento posterior, incluyendo
            aquella información que el "USUARIO" le proporcione a través de
            cualquier otro medio distinto al “PORTAL” , así como, la información
            que obtenga a través de las Cookies que se describen en el inciso
            COOKIES.
            <br />
            <br />
            <br />
            <span>Uso de la Información no confidencial o individual.</span>
            <br />
            <br />
            Mediante el uso del "PORTAL", el "USUARIO" autoriza a “CSN” a
            utilizar, publicar, reproducir, divulgar, comunicar públicamente y
            transmitirla información no confidencial o no individual, en
            términos de lo establecido en el artículo 109 de la Ley Federal de
            los Derechos de Autor y de la fracción l, del artículo 76 bis de la
            Ley Federal de Protección al Consumidor.
            <br />
            <br />
            <br />
            <span>Cookies.</span>
            <br />
            <br />
            El "USUARIO" que tenga acceso al "PORTAL", conviene en recibir
            archivos que les transmitan los servidores de “CSN”; "Cookies"
            significa un archivo de datos que se almacena en el disco duro de la
            computadora del Usuario cuando éste tiene acceso al "PORTAL",
            Páginas, Aplicaciones, o navegar por INTERNET; Dichos archivos
            pueden contener información tal como la identificación proporcionada
            por el "USUARIO" o información para rastrear las páginas que este
            último ha visitado; Una Cookie no puede leer los datos o información
            del disco duro del "USUARIO" ni leer las Cookies creadas por otro
            sitio o página.
            <br />
            <br />
            <br />
            <span>Aviso de Privacidad de Datos Personales.</span>
            <br />
            <br />
            Toda la información que “CSN” recabe del "USUARIO" es tratada con
            absoluta confidencialidad conforme las disposiciones legales
            aplicables; y autoriza mediante la aceptación de estos Términos y
            Condiciones a transferir la información necesaria para participar
            dentro de las licitaciones ofertadas por CSN en el “PORTAL”. Para
            conocer más información de la protección de sus datos personales,
            acuda a la siguiente liga: https://www.csn.coop/portal/privacidad .
            <br />
            <br />
            <br />
            <span>Claves de Acceso.</span>
            <br />
            <br />
            En todo momento, el "USUARIO" es el responsable único y final de
            mantener en secreto sus claves de acceso, usuario y contraseña, con
            la cual tenga acceso a ciertos "Servicios y Contenidos" del
            "PORTAL"; así como a las páginas de terceros.
            <br />
            <br />
            <br />
            <span>Modificaciones</span>
            <br />
            <br />
            CSN Tendrá el derecho de modificar en cualquier momento los
            "Términos y Condiciones, Uso y Privacidad" y demás condiciones aquí
            plasmadas; En consecuencia, el "USUARIO" debe leer atentamente los
            "Términos y Condiciones, Uso y Privacidad" y demás condiciones aquí
            plasmadas cada vez que pretenda utilizar el "PORTAL"; Ciertos
            Servicios y Contenidos ofrecidos al "USUARIO" en y/o a través del
            "PORTAL" están sujetos a condiciones particulares propias que
            sustituyen, completan y/o modifican los "Términos y Condiciones de
            Uso y Privacidad y demás condiciones aquí descritas.
            <br />
            <br />
            <br />
            <span>Política de integridad.</span>
            <br />
            <br />
            El “USUARIO” se da por enterado de que, como elementos fundamentales
            de su política de integridad, “CSN” cuenta con Códigos de Ética y de
            Conducta, el cual contiene los principios, valores, directrices y
            reglas de integridad aplicables a sus colaboradores y sus relaciones
            con terceros.
            <br />
            <br />
            Asimismo, el “USUARIO” se obliga a comunicar inmediatamente a “CSN”,
            cualquier hecho o circunstancia que se considere contraria a los
            principios que rige el referido Código de Ética y demás normatividad
            aplicable. Para tal efecto, “CSN” pone a su disposición una Línea de
            denuncia https://letica.mx/csn?locale=es .
            <br />
            <br />
            <br />
            <span>Leyes Aplicables y Jurisdicción.</span>
            <br />
            <br />
            Para la interpretación, y ejecución de los presentes "Términos y
            Condiciones de Uso y Privacidad y demás condiciones aquí plasmadas,
            el "USUARIO" está de acuerdo en que serán aplicables las leyes
            Federales de los Estados Unidos Mexicanos y competentes los
            tribunales de la Ciudad de Monterrey, Nuevo León, renunciando
            expresamente a cualquier otro fuero o jurisdicción que pudiera
            corresponderles en razón de sus domicilios presentes o futuros o por
            cualquier otra causa.
            <br />
            <br />
            <br />
            <span>Aceptación.</span>
            <br />
            <br />
            El "USUARIO" acepta, aprueba, ratifica la aceptación de todo lo que
            aquí se ha establecido a su entera satisfacción
            <br />
            <br />
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </div>
  );
};

export default Terms;
