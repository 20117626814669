import React from "react";
import bgimage from "../Assets/bg.png";
import lowerbg from "../Assets/lowerBg.png";
import { IoMdSearch } from "react-icons/io";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Link } from "react-router-dom";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const marks = [
  {
    value: 0,
    label: '0',
  },
 
  {
    value: 100,
    label: '100',
  },
];

function valuetext(value) {
  return `${value}°C`;
}

const FinancePage = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      {/* <Header /> */}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="flex flex-col w-full">
        <div
          className="w-full h-[400px] sm:h-[420px] 2xl:h-[485px] flex items-end bg-cover bg-center bg-no-repeat pb-[20px] sm:pb-[70px]"
          style={{ backgroundImage: `url(${bgimage})` }}
        >
          <div className="flex items-end gap-[15px] sm:gap-[20px] pl-[10%] w-full">
            <img
              src={require("../Assets/chiefImage.png")}
              className="w-[70px] sm:w-[90px] 2xl:w-[105px]"
              alt=""
            />
            <div className="flex flex-col ">
              <div className="text-[20px] leading-[25px] sm:text-[27px]  2xl:text-[32px] sm:leading-[42px] font-[700] text-[#FFFFFF]">
                Bocadillos y Canapes
              </div>
              <div className="flex gap-[8px] mt-[6px] sm:mt-[8px] items-end">
                <img
                  src={require("../Assets/location.png")}
                  className="w-[16px] sm:w-[20px] 2xl:w-[24px]"
                  alt=""
                />
                <div className="text-[16px] sm:text-[20px] leading-[23px] font-[400] text-[#FFFFFF]">
                  Monterrey, N.L.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-center gap-[20px] sm:gap-[45px] mt-[20px] sm:mt-[30px]">
          <img
            src={require("../Assets/triangle.png")}
            className="w-[30px] sm:w-[41px]"
            alt=""
          />
          <div className="text-[18px] sm:text-[24px] leading-[25px] sm:leading-[31px] font-[700] text-[#71A850]">
            Licitaciones en las que participa
          </div>
        </div>
        <div className="w-full min-h-[1000px] lg:min-h-[1144px] flex  relative  mb-[70px]">
          <img
            src={require("../Assets/lowerBg.png")}
            className="absolute w-full h-full top-0 z-1"
            alt=""
          />
          <div className="w-full relative px-[10%] flex-col flex lg:flex-row lg:justify-between pt-[30px] z-2">
            <div className="flex flex-col items-center sm:items-start">
              <div className="w-[304px] z-10 h-[32px] inputborder rounded-[8px] pr-[30px] pl-[5px] relative   border-[1px] border-[#C7C7CD]  bg-white">
                <input
                  type="text"
                  placeholder="Buscar Licitación..."
                  className="h-full w-full border-none bg-none pl-[10px] outline-none"
                />
                <IoMdSearch className="text-[20px] absolute right-[4px] top-[3px] text-black" />
              </div>
              <div className="w-[261px] boxShadowClass rounded-[14px] mt-[20px] lg:mt-[80px] bg-[#d9d9d9]">
                <div className="flex justify-center w-full items-end h-[181px] relative">
                  <img
                    src={require("../Assets/cakes.png")}
                    className="w-full h-full absolute top-0 z-1"
                    alt=""
                  />
                       <div className="mb-[10px] flex items-center mx-auto bg-[#d9d9d9e9] relative z-2 rounded-[8px] w-[95%] h-[46px]  justify-center">
                       <FlipClockCountdown
  labels={["DIAS", "HORAS", "MINUTOS", "SEGUNDOS"]}
  labelStyle={{
    fontSize: 10,
    fontWeight: 500,
    textTransform: "uppercase",
  }}
  digitBlockStyle={{
    width: 20,
    height: 20,
    fontSize: 16,
    color: "black",
    backgroundColor: "rgba(203, 213, 225, 0.95)",
  }}
  dividerStyle={{
    color: "red",
    height: 0,
  }}
  separatorStyle={{ color: "transparent", fontSize: 0 }}
  to={"2024-12-31 23:59:59"} // Target date example
>
  {/* Component rendered when timer finishes */}
  <div className="flex items-center justify-center px-2 py-1">
    <h3 className="text-center">Deadline passed!</h3>
  </div>
</FlipClockCountdown>
        </div>
                  {/* <div className="mb-[10px] flex items-center mx-auto bg-[#D9D9D9D1] relative z-2 rounded-[8px] w-[233px] h-[43px] px-[10px] justify-between">
                    <div className="flex flex-col items-center">
                      <div className="text-[20px] leading-[23px] text-[#000000] font-[700] ">
                        25
                      </div>
                      <div className="text-[10px] leading-[12px] text-[#4A4F54] font-[500]">
                        Días
                      </div>
                    </div>
                    <div className="flex flex-col items-center">
                      <div className="text-[20px] leading-[23px] text-[#000000] font-[700] ">
                        13
                      </div>
                      <div className="text-[10px] leading-[12px] text-[#4A4F54] font-[500]">
                        Horas
                      </div>
                    </div>
                    <div className="flex flex-col items-center">
                      <div className="text-[20px] leading-[23px] text-[#000000] font-[700] ">
                        52
                      </div>
                      <div className="text-[10px] leading-[12px] text-[#4A4F54] font-[500]">
                        Minutos
                      </div>
                    </div>
                    <div className="flex flex-col items-center">
                      <div className="text-[20px] leading-[23px] text-[#000000] font-[700] ">
                        43
                      </div>
                      <div className="text-[10px] leading-[12px] text-[#4A4F54] font-[500]">
                        Segundos
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="flex flex-col w-full">
                  <div className="text-[14px] leading-[16px] font-medium text-[#4A4F54] ml-[40px] mt-[10px]">
                    Ambos
                  </div>
                  <div className="text-[20px] sm:text-[24px] leading-[28px] font-extrabold text-[#4A4F54] mt-[8px] text-center">
                    Canapés Gourmet
                  </div>
                  <Link to="/system/licitation?id=672c8d59baa6e974e389035f">
                  <button className="w-[130px] mt-[26px] h-[40px] flex items-center justify-center gap-[10px] text-[14px] leading-[16px] text-white font-russo btnBorder bg-[#025624] rounded-[9px] mx-auto">
                    <img src={require("../Assets/btnImage.png")} alt="" />
                    VER MÁS
                  </button>
                  </Link>
                
                  <button
                    onClick={() => setModalShow(true)}
                    className="w-[130px] mt-[10px] mb-[10px] h-[40px] flex items-center justify-center gap-[10px] text-[14px] leading-[16px] text-white font-russo btnBorder bg-[#025624] rounded-[9px] mx-auto"
                  >
                    <img src={require("../Assets/votorIcon.png")} alt="" />
                    VOTAR
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center sm:items-start sm:flex-row gap-[20px] sm:gap-[40px] mt-[30px] lg:mt-0">
              <Form.Select className="w-[174px] h-[40px] selectBorder bg-[#EDEDEF] font-medium text-[10px] text-[#828282]">
                <option>Todas</option>
                <option>Abiertas</option>
                <option>Cerradas</option>
              </Form.Select>
              <div className="w-[240px] h-fit bg-[#EDEEEE] boxShadowClass flex items-center flex-col rounded-[12px] pt-[20px]">
                <div className="text-[20px] sm:text-[24px] leading-[25px] sm:leading-[31px] font-bold text-center text-[#4A4F54]">
                  Contactar a Proveedor
                </div>
                <input
                  type="text"
                  className="w-[182px] mx-auto h-[29px] pl-[10px] mt-[20px] bg-[white] outline-none text-[12px] leading-[14px] text-[#BBBABA] inputborder"
                  placeholder="Nombre:"
                />
                <input
                  type="email"
                  className="w-[182px] mx-auto h-[29px] pl-[10px] mt-[20px] bg-[white] outline-none text-[12px] leading-[14px] text-[#BBBABA] inputborder"
                  placeholder="Email:"
                />
                <select
                  type="text"
                  className="w-[182px] mx-auto h-[29px] pl-[10px] mt-[20px] bg-[white] outline-none text-[12px] leading-[14px] text-[#BBBABA] inputborder"
                  placeholder="Proyecto:"
                  >
                    <option value="">Proyecto:</option>
                    <option value="">Option2</option>
                    <option value="">Option3</option>
                  </select>
                <textarea
                  placeholder="Mensaje:"
                  className="w-[182px] mx-auto h-[130px] pt-[10px] pl-[10px] mt-[20px] bg-[white] outline-none text-[12px] leading-[14px] text-[#BBBABA] inputborder"
                ></textarea>
                <button className="w-[130px] mt-[10px] mb-[10px] h-[40px] flex items-center justify-center gap-[10px] text-[14px] leading-[16px] text-white btnBorder bg-[#025624] rounded-[9px] mx-auto">
                  <div className="flex items-center">
                    <img src={require("../Assets/btnArrow.png")} alt="" />
                    <img src={require("../Assets/btnTriangle.png")} alt="" />
                  </div>
                  ENVIAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default FinancePage;

function MyVerticallyCenteredModal(props) {

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-none">
        <Modal.Title id="contained-modal-title-vcenter" className="w-full">
          {/* <div className="text-[25px] sm:text-[36px] text-center leading-[47px] font-bold text-[#71A850]">
            {" "}
            ¡FELICIDADES!
          </div> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col items-center">
          <img
            src={require("../Assets/chief2.png")}
            className="w-[80px] sm:w-[109px] h-[80px] rounded-xl sm:h-[109px]"
            alt=""
          />
          <div className="text-[25px] sm:text-[32px] leading-[30px] sm:leading-[42px] font-bold text-[#4A4F54] mt-[10px]">
            Bocadillos y Canapes
          </div>
          <div className="text-[25px] sm:text-[32px] leading-[30px] sm:leading-[42px] font-bold text-[#71A850] mt-[10px] flex justify-start items-start ">
          Calificación
          </div>
          <br />
          <Box   sx={{
    width: {
      xs: '90%', // Full width on extra-small screens
      sm: '80%',    // 300px width on small screens
      md: '70%',    // 400px width on medium screens
      lg: '75%',    // 500px width on large screens
    },
  }}
>
      <Slider
        aria-label="Always visible"
        defaultValue={78}
        getAriaValueText={valuetext}
        step={1}
        marks={marks}
        valueLabelDisplay="on"
        sx={{
          color: '#71A850', // Change the slider color
          '& .MuiSlider-thumb': {
            backgroundColor: '#71A850', // Change the thumb color
          },
          '& .MuiSlider-track': {
            backgroundColor: '#71A850', // Change the track color
          },
          '& .MuiSlider-rail': {
            backgroundColor: '#D3D3D3', // Change the rail color (optional)
          },
        }}
      />
    </Box>
    <button
                    className="w-[130px] mt-[10px] mb-[10px] h-[40px] flex items-center justify-center gap-[10px] text-[14px] leading-[16px] text-white font-russo btnBorder bg-[#025624] rounded-[9px] mx-auto"
                  >
                    <img src={require("../Assets/votorIcon.png")} alt="" />
                    VOTAR
                  </button>
          {/* <div className="mt-[30px] text-[20px] sm:text-[24px] leading-[24px] sm:leading-[28px] mb-[70px]">
            ¡Haz resultado ganador de la licitación de:{" "}
            <span className="text-[#71A850]">Canapés Gourmet!</span>{" "}
          </div> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}
