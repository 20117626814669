import React, { useState } from "react";
import styles from "./SupRegister.module.css";
import RegIcn from "../../Images/RegIcn.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SupRegStep from "../../Images/supRegStep.png";
import FileUpIcn from "../../Images/fileupIcn.png";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const SupRegister = () => {
  const navigate = useNavigate()
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top smoothly
  };

  // State for all form data
  const [formDataNew, setFormData] = useState({
    businessType: "",
    giro: "",
    origin: "",
    itemsServices: "",
    socialReason: "",
    commercialName: "",
    rfc: "",
    emailUser: "",
    emailUserLastName: "",
    legalRepresentative: {
      firstName: "",
      lastName: ""
    },
    files: {
      logo: null,
      document: null
    }
  });

  const [fileName, setFileName] = useState(
    "Imagen formato jpeg formato web ancho: 96 px x alto: 96 px"
  );
  const [fileName2, setFileName2] = useState(
    "Imagen formato jpeg formato web ancho: 1920 px X alto: 705 px"
  );
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => {
      const updatedData = {
        ...prev,
        [name]: value
      };
      console.log("Updated formData:", updatedData); // Log the updated state
      return updatedData;
    });
  };
  
  const handleLegalRepresentativeChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => {
      const updatedData = {
        ...prev,
        legalRepresentative: {
          ...prev.legalRepresentative,
          [name]: value
        }
      };
      console.log("Updated legal representative:", updatedData.legalRepresentative); // Log the legal representative state
      return updatedData;
    });
  };
  
  const handleFileChange = (event, fileKey) => {
    const file = event.target.files[0];
    if (file) {
      setFormData(prev => {
        const updatedData = {
          ...prev,
          files: {
            ...prev.files,
            [fileKey]: file
          }
        };
        console.log(`Updated ${fileKey}:`, updatedData.files[fileKey]); // Log the updated file
        return updatedData;
      });
      fileKey === "logo" ? setFileName(file.name) : setFileName2(file.name);
    } else {
      fileKey === "logo" ? setFileName("No file chosen") : setFileName2("No file chosen");
    }
  };
  
  const handleSubmit = async () => {
    console.log("Submitting form data:", formDataNew); // Log the complete form data before submission
    // Rest of your submit logic...

    const formData = new FormData();

    formData.append("supplierOf", formDataNew.businessType);
    formData.append("companyLineOfBusiness", formDataNew.giro);
    formData.append("origin", formDataNew.origin);
    formData.append("serviceProvided", formDataNew.itemsServices);
    formData.append("companyName", formDataNew.socialReason);
    formData.append("tradeName", formDataNew.commercialName);
    formData.append("rfc", formDataNew.rfc);
    formData.append("fullName", formDataNew.emailUser);
    formData.append("surName", formDataNew.emailUserLastName);
    formData.append("legalFullName", formDataNew.legalRepresentative.firstName);
    formData.append("legalSurname", formDataNew.legalRepresentative.lastName);
    formData.append("filesStep3", formDataNew.files.document);
    formData.append("filesStep3", formDataNew.files.logo);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/signup-step-3`,
        formData,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      alert("Signup step 3 Successful!");
      console.log(response);
      navigate("/system/contactinfo"); // Redirect to dashboard or home page
    } catch (error) {
      alert("Signup failed!");
      console.error(error);
    }
  };
  

  return (
    <div>
      <div className={styles.supRegisterMain}>
        <div className={styles.supRegisterContainer}>
          <div className={styles.supRegisterContainer2}>
            <div className={styles.registerMainHeading}>
              <img src={RegIcn} alt="" className={styles.regIcn} />
              Registro de Proveedor
            </div>
            <div className={styles.supRegStepMain}>
              <img src={SupRegStep} alt="" className={styles.supRegstep} />
            </div>

            <div className={styles.radioMaindiv}>
              <div className={styles.radioText}>Proveedor de:*</div>
              <div className={styles.radioTextBtn}>
                <label className="custom-radio">
                  <input 
                    type="radio" 
                    name="businessType" 
                    value="Bienes" 
                    onChange={handleChange} 
                  />
                  <span className="checkmark"></span>
                </label>
                <div className={styles.radioText}>Bienes</div>
              </div>
              <div className={styles.radioTextBtn}>
                <label className="custom-radio">
                  <input 
                    type="radio" 
                    name="businessType" 
                    value="Servicios" 
                    onChange={handleChange} 
                  />
                  <span className="checkmark"></span>
                </label>
                <div className={styles.radioText}>Servicios</div>
              </div>
              <div className={styles.radioTextBtn}>
                <label className="custom-radio">
                  <input 
                    type="radio" 
                    name="businessType" 
                    value="Ambos" 
                    onChange={handleChange} 
                  />
                  <span className="checkmark"></span>
                </label>
                <div className={styles.radioText}>Ambos</div>
              </div>
            </div>

            <div className={styles.registerInputMain}>
              Giro de la empresa:*
              <input 
                type="text" 
                name="giro" 
                className={styles.inputRegister} 
                onChange={handleChange} 
              />
            </div>
            <div className={styles.radioMaindiv2}>
              <div className={styles.radioText3}>Origen:*</div>
              <div className={styles.radioText2}>Local:</div>
              <div className={styles.radioTextBtnMain}>
                <div className={styles.radioTextBtn}>
                  <label className="custom-radio">
                    <input 
                      type="radio" 
                      name="origin" 
                      value="Nuevo Léon" 
                      onChange={handleChange} 
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div className={styles.radioText}>Nuevo Léon</div>
                </div>
                <div className={styles.radioTextBtn}>
                  <label className="custom-radio">
                    <input 
                      type="radio" 
                      name="origin" 
                      value="Nacional" 
                      onChange={handleChange} 
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div className={styles.radioText}>Nacional</div>
                </div>
              </div>
              <div className={styles.radioTextBtnMain}>
                <div className={styles.radioTextBtn}>
                  <label className="custom-radio">
                    <input 
                      type="radio" 
                      name="origin" 
                      value="Coahuila" 
                      onChange={handleChange} 
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div className={styles.radioText}>Coahuila</div>
                </div>
                <div className={styles.radioTextBtn}>
                  <label className="custom-radio">
                    <input 
                      type="radio" 
                      name="origin" 
                      value="Internacional" 
                      onChange={handleChange} 
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div className={styles.radioText}>Internacional</div>
                </div>
              </div>
            </div>
            <div className={styles.registerInputMain}>
              Artículo(s) o servicio(s) que provee:*
              <input 
                type="text" 
                name="itemsServices" 
                className={styles.inputRegister} 
                onChange={handleChange} 
              />
            </div>
            <div className={styles.registerInputMain}>
              Razón social:*
              <input 
                type="text" 
                name="socialReason" 
                className={styles.inputRegister} 
                onChange={handleChange} 
              />
            </div>
            <div className={styles.registerInputMain}>
              Nombre Comercial:
              <input 
                type="text" 
                name="commercialName" 
                className={styles.inputRegister} 
                onChange={handleChange} 
              />
            </div>
            <div className={styles.registerInputMain}>
              RFC:*
              <input 
                type="text" 
                name="rfc" 
                className={styles.inputRegister} 
                onChange={handleChange} 
              />
            </div>
            <div className={styles.regDocmainText2}>
              Logotipo o Avatar representativo del proveedor:*
            </div>
            {/* File Upload 1 */}
            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload1"
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, 'logo')}
                style={{ display: "none" }}
                 accept=".jpeg"
              />
              <span className={styles.fileName}>{fileName}</span>
              <label htmlFor="fileUpload1" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>
            <div className={styles.regDocmainText2}>
            Encabezado representativo del proveedor:*
                        </div>
            {/* File Upload 2 */}
            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload2"
                className={styles.regdocFileUp}
                onChange={(e) => handleFileChange(e, 'document')}
                style={{ display: "none" }}
                 accept=".jpeg"
              />
              <span className={styles.fileName}>{fileName2}</span>
              <label htmlFor="fileUpload2" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>

            <div className={styles.registerInputMain}>
            Nombre del Contacto Directo:*
              <div className={styles.registerInputboth}>
                <input 
                  type="text" 
                  name="emailUser" 
                  placeholder="Nombre(s) Completo(s):" 
                  className={styles.inputRegister} 
                  onChange={handleChange} 
                />
                <input 
                  type="text" 
                  name="emailUserLastName" 
                  placeholder="Apellido(s)" 
                  className={styles.inputRegister} 
                  onChange={handleChange} 
                />
              </div>
            </div>
            <div className={styles.registerInputMain}>
              Representante Legal:
              <div className={styles.registerInputboth}>
                <input 
                  type="text" 
                  name="firstName" 
                  placeholder="Nombre(s) Completo(s):" 
                  className={styles.inputRegister} 
                  onChange={handleLegalRepresentativeChange} 
                />
                <input 
                  type="text" 
                  name="lastName" 
                  placeholder="Apellido(s)" 
                  className={styles.inputRegister} 
                  onChange={handleLegalRepresentativeChange} 
                />
              </div>
            </div>
            <div className={styles.RegBtnIcnMain}>
              <button className={styles.RegBtnIcn2} onClick={handleSubmit}>
                <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
                <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
                <span>ENVIAR</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupRegister;
