import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";
import styles from "../ProjectRegister/ProjectRegister.module.css";

const InternasContra = () => {
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const data = [
    {
      name: "Bocadillos y Canapes",
      text: "Monterrey, N.L.",
      img: require("../../Images/Intra/l2.png"),
      bg: require("../../Images/Intra/I1.png"),
      subData: [
        {
          text: "FO-ADQ-007 Evaluación de proveedor.*",
        },
        {
          text: "Carta de confidencialidad firmada.*",
        },
        {
          text: "Acta constitutiva.",
        },
        {
          text: "Poder Legal del Representante.",
        },
        {
          text: "Aviso de privacidad firmado.*",
        },
        {
          text: "Firma de aceptación de política anti soborno.*",
        },
        {
          text: "Identificación vigente de persona física. *",
        },

        {
          text: "Opinión de cumplimiento positiva por el SAT.*",
        },
        {
          text: "Constancia de situación fiscal emitida  por el SAT.*",
        },
        {
          text: "Identificación oficial del proveedor o representante.",
        },
        {
          text: "Permiso vigente de Seguridad Pública del Estado.",
        },
        {
          text: "Control Interno Investigación Proveedor.*",
        },
      ],
    },
    {
      name: "Alimentus",
      text: "Monterrey, N.L.",
      img: require("../../Images/Intra/l2.png"),
      bg: require("../../Images/Intra/I1.png"),
      subData: [
        {
          text: "FO-ADQ-007 Evaluación de proveedor.*",
        },
        {
          text: "Carta de confidencialidad firmada.*",
        },
        {
          text: "Acta constitutiva.",
        },
        {
          text: "Poder Legal del Representante.",
        },
        {
          text: "Aviso de privacidad firmado.*",
        },
        {
          text: "Firma de aceptación de política anti soborno.*",
        },
        {
          text: "Identificación vigente de persona física. *",
        },

        {
          text: "Opinión de cumplimiento positiva por el SAT.*",
        },
        {
          text: "Constancia de situación fiscal emitida  por el SAT.*",
        },
        {
          text: "Identificación oficial del proveedor o representante.",
        },
        {
          text: "Permiso vigente de Seguridad Pública del Estado.",
        },
        {
          text: "Control Interno Investigación Proveedor.*",
        },
      ],
    },
    {
      name: "Marlene Portillo Eventos",
      text: "Monterrey, N.L.",
      img: require("../../Images/Intra/l2.png"),
      bg: require("../../Images/Intra/I1.png"),
      subData: [
        {
          text: "FO-ADQ-007 Evaluación de proveedor.*",
        },
        {
          text: "Carta de confidencialidad firmada.*",
        },
        {
          text: "Acta constitutiva.",
        },
        {
          text: "Poder Legal del Representante.",
        },
        {
          text: "Aviso de privacidad firmado.*",
        },
        {
          text: "Firma de aceptación de política anti soborno.*",
        },
        {
          text: "Identificación vigente de persona física. *",
        },

        {
          text: "Opinión de cumplimiento positiva por el SAT.*",
        },
        {
          text: "Constancia de situación fiscal emitida  por el SAT.*",
        },
        {
          text: "Identificación oficial del proveedor o representante.",
        },
        {
          text: "Permiso vigente de Seguridad Pública del Estado.",
        },
        {
          text: "Control Interno Investigación Proveedor.*",
        },
      ],
    },
  ];

  // Filter the data based on the searchTerm
  const filteredData = data.filter(item => 
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
    item.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      {/* <Header /> */}
      <div className="mx-auto mt-32 mb-16 flex items-center justify-center gap-4 sm:gap-10">
          <img
            src={require("../../Assets/triangle.png")}
            className="h-9 w-9 sm:h-16 sm:w-16"
            alt=""
          />
          <div className="text-md font-bold leading-[30px] text-[#71A850] sm:text-2xl sm:leading-[47px] md:text-4xl">
          Validaciones Internas
          </div>
        </div>

      <div className="max-w-[1250px] mx-auto  flex flex-col gap-[20px]   md:flex-row justify-center md:justify-between items-center ">
        <div className="w-[287px] h-[57px]  flex justify-center items-center px-[20px] bg-[#D9D9D9] rounded-[12px]">
        <input
            type="text"
            name=""
            id=""
            placeholder="Buscar Proveedor..."
            className="outline-none border-none bg-transparent"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update the search term
          />
          <img src={require("../../Images/Intra/search.png")} alt="" />
        </div>

        <div className="w-[287px] h-[57px]  flex justify-center items-center px-[20px] bg-[#D9D9D9] rounded-[12px]">
          <label for="options" className="text-[22px] text-[#828282] font-bold">
            Proveedor de:
          </label>
          <select id="options" name="options" className="bg-[#D9D9D9]">
           
            <option value="option2">Bienes</option>
            <option value="option2">Servicios</option>
            <option value="option3">Ambos</option>
          </select>
        </div>
      </div>

      <div className="max-w-[1250px] mx-auto mt-[40px] gap-[30px] xl:gap-[0px]  px-[20px] xl:px-[0px] w-full flex justify-center  lg:justify-between items-center flex-wrap  ">
        {filteredData.map((item, index) => {
          return (
            <div className="flex flex-col  flex-start">
              <div
                className="flex rounded-t-xl relative justify-between items-start pt-[50px] pl-[20px] pr-[30px] max-w-[380px]"
                style={{
                  backgroundImage: `url(${item.bg})`,
                  backgroundSize: "cover", // Make the background cover the entire div
                  backgroundPosition: "center", // Center the background image
                  height: "296px", // Set a specific height for the div
                  width: "100%", // Set the width to 100%
                }}
              >
                <div>
                  <div className="font-bold text-[20px] leading-[26px] fontClass text-white">
                    {item.name}
                  </div>
                  <div className="text-[15px] leading-[17px] fontClass text-white">
                    {item.text}
                  </div>
                </div>
                <div className="bg-white rounded-[12px] p-[5px]">
                  <img src={item.img} alt="" />
                </div>
                <Link to="/system/financepage">
                <div className="absolute right-[100px] bottom-[-30px] box">
                  <img src={require("../../Images/Intra/circle.png")} alt="" />
                </div>
                </Link>

              </div>
              <div className="pt-[30px] pb-[50px] pl-[20px] pr-[10px] rounded-b-xl mx-auto w-full  border-[1px]  border-[#BBBABA]  box ">
                {item.subData.map((item2, index) => {
                  return (
                    <div
                      key={index}
                      className="flex mt-[10px] gap-[10px] justify-start items-center"
                    >
                      <input type="checkbox" id={`checkbox-${index}`} />
                      <label
                        htmlFor={`checkbox-${index}`}
                        className="text-[#4A4F54] text-[13px] leading-[15px] max-w-[300px] fontClass"
                      >
                        {item2.text}
                      </label>
 
                    </div>
                                     
                  );
             
                })}
                     <div className={styles.RegBtnIcnMain}>
                  <button className={styles.RegBtnIcn2} >
                    <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
                    <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
                    <span>GUARDAR</span>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-[50px]">{/* <Footer /> */}</div>
    </div>
  );
};

export default InternasContra;
