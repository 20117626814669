import React, { useState } from "react";
import styles from "./Login.module.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import RegIcn from "../../Images/RegIcn.png";
import HideIcn from "../../Images/hideIcn.png";
import BtnIcn from "../../Images/headBtnIcn1.png";
import LoginStep from "../../Images/loginStepper.png";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({ username: "", password: "", rememberMe: false });
  const [isLoginChecked, setIsLoginChecked] = useState(false); // State for login checkbox
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };
      console.log("Updated formData:", updatedData); // Log the updated state
      return updatedData;
    });
  };
  // Handle login
  const handleLogin = async () => {
    const { username, password } = formData;

    console.log("Login Data:", formData); // Log the login data

   
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/login`,
        {
          email: username,
          password: password,
          role: "provider",
        }
      );
      console.log("token", response.data?.token);
      localStorage.setItem("token", response.data?.token);
      alert("Login Successful!");
      console.log("........");
      navigate("/system/pro-register"); // Redirect to dashboard or home page
    } catch (error) {
      alert("Login failed!");
      console.error(error);
    }
  };
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      {/* <Header /> */}
      <div className={styles.registerMain}>
        <div className={styles.registerContainer}>
          <div className={styles.registerContainer2}>
            <div className={styles.registerLeftMain}>
              <div className={styles.registerMainHeading}>
                <img src={RegIcn} alt="" className={styles.regIcn} />
                Ingreso de Sesión
              </div>
              <div className={styles.loginStepperMain}>
                <img src={LoginStep} alt="" className={styles.loginStepper} />
              </div>
              <div className={styles.loginText}>
                Sólo los administradores de la financiera tienen autorizado dar
                de alta proyectos para el concurso de licitaciones. Por esto se
                solicita primero ingresar a tu cuenta para verificar que puedas
                dar de alta nuevos proyectos.
              </div>
              <div className={styles.registerInputMain}>
                Usuario o correo electrónico:*
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  className={styles.inputRegister}
                />
              </div>
              <div className={styles.registerInputMain}>
                Contraseña:*
                <div className={styles.showPasswordInput}>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className={styles.passwordinput}
                  />
                  <img
                    src={HideIcn}
                    alt={showPassword ? "Hide password" : "Show password"}
                    className={styles.hideIcn}
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div className={styles.forgoText}>¿Olvidé Contraseña?</div>
              <div className={styles.registerCheckbox}>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    name="rememberMe"
                    checked={formData.rememberMe}
                    onChange={handleInputChange}
                  />
                  <span className="checkmark"></span>
                </label>
                Recuérdame
              </div>
              <div className={styles.RegBtnIcnMain}>
                {/* <Link to={"/pro-register"} onClick={handleLinkClick}> */}
                  <button className={styles.RegBtnIcn}    onClick={handleLogin}>
                    <img src={BtnIcn} alt="" className={styles.navBtnIcn} />
                    ENTRAR
                  </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Login;
