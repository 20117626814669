import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import styles from './LevelReport.module.css';
import { GiRank3 } from "react-icons/gi";
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightSLine } from "react-icons/ri";
import './LevelReport.css'
import { Line } from 'react-chartjs-2';
import Pagination from 'react-bootstrap/Pagination';
import Table from 'react-bootstrap/Table';


// Sample data for the double-line chart
const chartData = {
  labels: ['Feb 12', 'Feb 13', 'Feb 14', 'Feb 15', 'Feb 16', 'Feb 17', 'Feb 18'],
  datasets: [
    {
      label: 'Primeroslugares',
      data: [2.0, 0, 5.0, 1.0, 5, 3.0, 1.0],
      borderColor: 'rgb(52,183,95)',
      backgroundColor: 'transparent',
      fill: true,
      borderCapStyle: 'round',     // Rounded line ends
      borderJoinStyle: 'round',    // Rounded line corners
      tension: 0.4,                // Smoothens the line curve
    },
    {
      label: 'Primeros lugares (semana pasada)',
      data: [2.0, 1.0, 2.0, 1.0, 4.0,5.0, 2.0],
      borderColor: 'rgb(174,226,191)',
      backgroundColor: 'transparent',
      fill: true,
      borderCapStyle: 'round',     // Rounded line ends
      borderJoinStyle: 'round',    // Rounded line corners
      tension: 0.4,                // Smoothens the line curve
    },
  ],
};

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
  
  },
};
// another Chart 
const chartData2 = {
  labels: ['Feb 12', 'Feb 13', 'Feb 14', 'Feb 15', 'Feb 16', 'Feb 17', 'Feb 18'],
  datasets: [
    {
      label: 'Premiados',
      data: [2.0, 1.0, 1.0, 0, 0, 5.0, 1.0],
      borderColor: 'rgb(132,97,181)',
      backgroundColor: 'transparent',
      fill: true,
      borderCapStyle: 'round',     // Rounded line ends
      borderJoinStyle: 'round',    // Rounded line corners
      tension: 0.4,                // Smoothens the line curve
    },
    {
      label: 'Premiados (Semana anterior)',
      data: [4.0, 0, 4.0, 2.0, 5.0, 3.0, 2.0],
      borderColor: 'rgb(206,192,225)',
      backgroundColor: 'transparent',
      fill: true,
      borderCapStyle: 'round',     // Rounded line ends
      borderJoinStyle: 'round',    // Rounded line corners
      tension: 0.4,                // Smoothens the line curve
    },
  ],
};

const chartOptions2 = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
  
  },
};

const LevelReport = () => {
  const [key, setKey] = useState('Niveles');

  return (
    <div className={styles.levelReportMain}>
      <div className={styles.levelReportContainer}>
      <div className={styles.reportHeadingMain}>Reportes</div>
      <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="Niveles" title="Niveles">
      <div className={styles.cardsMain}>
  <div className={styles.cardsContainer}>
  <GiRank3 className={styles.rankIcn}/>
  <div className={styles.cardTextMain}>
<div className={styles.cardText1}>
  5
</div>
<div className={styles.cardText2}>
Activos
</div>
  </div>
  </div>
  <div className={styles.cardsContainer2}>
  <FaArrowUp className={styles.ArrowUpIcn}/>
  <div className={styles.cardTextMain}>
<div className={styles.cardText1}>
  0
</div>
<div className={styles.cardText3}>
Usuarios en los primeros lugares
</div>
  </div>
  </div>
  <div className={styles.cardsContainer3}>
  <FaArrowDown  className={styles.ArrowDownIcn}/>
  <div className={styles.cardTextMain}>
<div className={styles.cardText1}>
7
</div>
<div className={styles.cardText4}>
Usuarios en los últimos lugares
</div>
  </div>
  </div>
  </div>
  <div className={styles.graphMain}>
  <div className={styles.graphContainer}>
    <div className={styles.graphdateMain}>
      <div className={styles.graphDate}>
        Feb 12 - Feb 18 <span>vs Feb 05 - Feb 11</span>
      </div>
      <div className={styles.graphDateIcon}>
        <div className={styles.leftPagMain}>
        <RiArrowLeftSLine  className={styles.leftarrowIcn}/>

        </div>
        <div className={styles.leftPagMain}>
      <RiArrowRightSLine  className={styles.leftarrowIcn} />
        </div>
      </div>
    </div>
    <div className={styles.graphButtonMain}>
      <button className={styles.graphBtn}>Año</button>
      <button className={styles.graphBtn}>Mes</button>
      <button className={styles.graphBtn2}>Semana</button>
    </div>
    <div className={styles.ChartsMain}>
              <Line data={chartData} options={chartOptions} />
            </div>
<div className={styles.graphbottomText}>
  17
</div>
<div className={styles.graphbottomText2}>
0 (0.00%)
</div>
<div className={styles.graphbottomText3}>
Primeros Lugares
</div>
  </div>
  <div className={styles.graphContainer}>
    <div className={styles.graphdateMain}>
      <div className={styles.graphDate}>
        Feb 12 - Feb 18 <span>vs Feb 05 - Feb 11</span>
      </div>
      <div className={styles.graphDateIcon}>
        <div className={styles.leftPagMain}>
        <RiArrowLeftSLine  className={styles.leftarrowIcn}/>

        </div>
        <div className={styles.leftPagMain}>
      <RiArrowRightSLine  className={styles.leftarrowIcn} />
        </div>
      </div>
    </div>
    <div className={styles.graphButtonMain}>
      <button className={styles.graphBtn}>Año</button>
      <button className={styles.graphBtn}>Mes</button>
      <button className={styles.graphBtn2}>Semana</button>
    </div>
    <div className={styles.ChartsMain}>
              <Line data={chartData2} options={chartOptions2} />
            </div>
<div className={styles.graphbottomText}>
  10
</div>
<div className={styles.graphbottomText4}>
-10 (-100.00%)
</div>
<div className={styles.graphbottomText3}>
Premiados
</div>
  </div>
  </div>
  <div className={styles.tableMain}>
    <div className={styles.tableContainer}>
    <Pagination className={styles.pagiMain}>
      <Pagination.First />
      <Pagination.Prev />
      <Pagination.Item>{1}</Pagination.Item>
      <div className={styles.pagiText}>
        of 2
      </div>
      <Pagination.Next />
      <Pagination.Last />
    </Pagination>
    <div className={styles.tableMain2}>
    <Table responsive className={styles.tableMain2}>
      <thead>
        <tr>
          <th className={styles.tableHeadings}>Proveedores</th>
          <th className={styles.tableHeadings}>Lugares</th>
          <th className={styles.tableHeadings}>Fecha</th>
        
        </tr>
      </thead>
      <tbody>
        <tr className={styles.colorRow}>
          <td className={styles.tableTextFirst}>Bocadillos y Canapés</td>
          <td className={styles.tableTextTwo}>1</td>
          <td className={styles.tableTextTwo}>17/08/2024</td>
         
        </tr>
        <tr>
        <td className={styles.tableTextFirst}>Cinco Catering</td>
          <td className={styles.tableTextTwo}>2</td>
          <td className={styles.tableTextTwo}>17/08/2024</td>
         
        </tr>
        <tr>
        <td className={styles.tableTextFirst}>Appetit Catering & Store</td>
        <td className={styles.tableTextTwo}>3</td>
        <td className={styles.tableTextTwo}>17/08/2024</td>
        
        </tr>
        <tr>
        <td className={styles.tableTextFirst}>Grupo Dimento</td>
        <td className={styles.tableTextTwo}>4</td>
        <td className={styles.tableTextTwo}>17/08/2024</td>
        
        </tr>
        <tr>
        <td className={styles.tableTextFirst}>Marlén Portillo Eventos</td>
        <td className={styles.tableTextTwo}>5</td>
        <td className={styles.tableTextTwo}>17/08/2024</td>
        
        </tr>
      </tbody>
    </Table>
    </div>
    <Pagination className={styles.pagiMain2}>
      <Pagination.First />
      <Pagination.Prev />
      <Pagination.Item>{1}</Pagination.Item>
      <div className={styles.pagiText}>
        of 2
      </div>
      <Pagination.Next />
      <Pagination.Last />
    </Pagination>
    </div>
    <div className={styles.tableContainer}>
   <div className={styles.spacetable}></div>
    <div className={styles.tableMain2}>
    <Table responsive className={styles.tableMain2}>
      <thead>
        <tr>
          <th className={styles.tableHeadings}>Lugares</th>
          <th className={styles.tableHeadings}>Cantidad</th>
          <th className={styles.tableHeadings}>Ganador más reciente</th>
        
        </tr>
      </thead>
      <tbody>
        <tr className={styles.colorRow}>
          <td className={styles.tableTextFirst}>1</td>
          <td className={styles.tableTextTwo}>3</td>
          <td className={styles.tableTextTwo}>Bocadillos y Canapés</td>
         
        </tr>
        <tr>
        <td className={styles.tableTextFirst}>2</td>
          <td className={styles.tableTextTwo}>4</td>
          <td className={styles.tableTextTwo}>Cinco Catering</td>
         
        </tr>
        <tr>
        <td className={styles.tableTextFirst}>3</td>
        <td className={styles.tableTextTwo}>1</td>
        <td className={styles.tableTextTwo}>Appetit Catering & Store</td>
        
        </tr>
        <tr>
        <td className={styles.tableTextFirst}>4</td>
        <td className={styles.tableTextTwo}>2</td>
        <td className={styles.tableTextTwo}>Grupo Dimento</td>
        
        </tr>
        <tr>
        <td className={styles.tableTextFirst}>5</td>
        <td className={styles.tableTextTwo}>2</td>
        <td className={styles.tableTextTwo}>Marlén Portillo Eventos</td>
        
        </tr>
      </tbody>
    </Table>
    </div>
    <div className={styles.spacetable}></div>

    </div>
  </div>






      </Tab>
      <Tab eventKey="Rangos" title="Rangos" disabled>
      </Tab>
      <Tab eventKey="Puntuaje" title="Puntuaje" disabled>
      </Tab>
    </Tabs>
      </div>
     
    </div>
  );
};

export default LevelReport;
