import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../Register/Register.module.css";
import RegIcn from "../../Images/RegIcn.png";
import axios from "axios";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";
import RegStep1 from "../../Images/regstep1.png";
import styles2 from  "./RegisterMain.module.css"
const RegisterMain = () => {
  const [isSignupChecked, setIsSignupChecked] = useState(false);
  const [formData, setFormData] = useState({
    loginEmail: "",
    loginPassword: "",
    signupEmail: "",
    password: "",
    confirmPassword: "",
  });
  const [isEmailValid, setIsEmailValid] = useState(true); // State to track email validation
  const navigate = useNavigate();

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "signupEmail") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsEmailValid(emailPattern.test(value));
    }
  };

  const handleSignupCheckboxChange = () => {
    setIsSignupChecked((prev) => !prev);
  };

  const handleSignup = async () => {
    const { password, confirmPassword, signupEmail } = formData;

    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    if (!isSignupChecked) {
      alert("Please agree to the terms and conditions for signup.");
      return;
    }
    if (!isEmailValid) {
      alert("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/signup-step-1`,
        {
          email: signupEmail,
          password: password,
          role: "provider",
        }
      );
      localStorage.setItem("token", response.data?.token);
      alert("Signup Successful!");
      navigate("/system/register-doc");
    } catch (error) {
      alert("Signup failed!");
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(isSignupChecked);
  }, [isSignupChecked]);

  return (
    <div className={styles.registerContainer}>
      <div className={styles.registerContainer2}>
        <div className={styles.registerRightMain}>
          <div className={styles.registerMainHeading}>
            <img src={RegIcn} alt="" className={styles.regIcn} />
            Registro de Usuario
          </div>
          <div className={styles.regStepMain}>
            <img src={RegStep1} alt="" className={styles.regStep1} />
          </div>
          <div className={styles.registerInputMain}>
            Correo electrónico:*
            <input
              type="email"
              name="signupEmail"
              className={`${styles.inputRegister} ${
                !isEmailValid ? styles2.invalidInput : ""
              }`}
              value={formData.signupEmail}
              onChange={handleChange}
            />
            {!isEmailValid && (
              <span className={styles2.errorMessage}>
                El correo electrónico no cumple con el formato correcto. Favor de corregir.
              </span>
            )}
          </div>
          <div className={styles.registerInputMain}>
            Contraseña:*
            <div className={styles.showPasswordInput}>
              <input
                type="password"
                name="password"
                className={styles.passwordinput}
                value={formData.password}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.registerInputMain}>
            Confirmar Contraseña:*
            <div className={styles.showPasswordInput}>
              <input
                type="password"
                name="confirmPassword"
                className={styles.passwordinput}
                value={formData.confirmPassword}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.registerCheckbox}>
            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={isSignupChecked}
                onChange={handleSignupCheckboxChange}
              />
              <span className="checkmark"></span>
            </label>
            <div className={styles.registerCheckboxText}>
              Consiento que mis datos personales sensibles sean tratados conforme a los términos y condiciones del presente{" "}
              <Link to={"/system/terms"} onClick={handleLinkClick}>
                <span>Términos y condiciones.</span>
              </Link>
            </div>
          </div>
          <div className={styles.RegBtnIcnMain}>
            <button
              onClick={handleSignup}
              className={styles.RegBtnIcn2}
              disabled={!isSignupChecked}
            >
              <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
              <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
              <span>ENVIAR</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterMain;
