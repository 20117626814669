import React from 'react'
import styles from "./Footer.module.css"
import FooterIcn from "../../Images/footerIcn.png"
import { Link } from 'react-router-dom';
const Footer = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top smoothly
  };
  return (
    <div className={styles.footerMain}>
        <div className={styles.footerContainer}>
            <div className={styles.footerText}>
                <img src={FooterIcn} alt="" className={styles.footerIcn} />
            2024 CSN Cooperativa Financiera. Todos los Derechos Reservados.
            <Link to={"/system/terms"} onClick={handleLinkClick}>
            <span>Términos y condiciones.</span> 
      </Link>

            </div>
        </div>
      
    </div>
  )
}

export default Footer
