import React, { useState } from "react";
import styles from "./ContactInfo.module.css";
import RegIcn from "../../Images/RegIcn.png";
import ContactInfoStep from "../../Images/contactinfo.png";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';

const ContactInfo = () => {
  const navigate = useNavigate();

  // Define state for each input field
  const [formDataNew, setFormData] = useState({
    street: '',
    extNum: '',
    intNum: '',
    colony: '',
    municipality: '',
    state: '',
    country: '',
    postalCode: '',
    fiscalStreet: '',
    fiscalExtNum: '',
    fiscalIntNum: '',
    fiscalColony: '',
    fiscalMunicipality: '',
    fiscalState: '',
    fiscalCountry: '',
    fiscalPostalCode: '',
    intlCode: '',
    phone: '',
    email: '',
    website: '',
  });

  const [isSameAddress, setIsSameAddress] = useState(false); // State for checkbox

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };
      console.log("Updated state:", updatedData); // Log the new state
      return updatedData;
    });
  };

  const handleCheckboxChange = () => {
    setIsSameAddress(!isSameAddress);

    if (!isSameAddress) {
      // Autofill fiscal address fields
      setFormData((prevData) => ({
        ...prevData,
        fiscalStreet: prevData.street,
        fiscalExtNum: prevData.extNum,
        fiscalIntNum: prevData.intNum,
        fiscalColony: prevData.colony,
        fiscalMunicipality: prevData.municipality,
        fiscalState: prevData.state,
        fiscalCountry: prevData.country,
        fiscalPostalCode: prevData.postalCode,
      }));
    } else {
      // Clear fiscal address fields if checkbox is unchecked
      setFormData((prevData) => ({
        ...prevData,
        fiscalStreet: '',
        fiscalExtNum: '',
        fiscalIntNum: '',
        fiscalColony: '',
        fiscalMunicipality: '',
        fiscalState: '',
        fiscalCountry: '',
        fiscalPostalCode: '',
      }));
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData()

    formData.append("physicalCalle", formDataNew.street);
    formData.append("physicalNumExt", formDataNew.extNum);
    formData.append("physicalNumInternational", formDataNew.intNum);
    formData.append("physicalColonia", formDataNew.colony);
    formData.append("physicalMunicipality", formDataNew.municipality);
    formData.append("physicalState", formDataNew.state);
    formData.append("physicalCountry", formDataNew.country);
    formData.append("physicalPostalCode", formDataNew.postalCode);

    formData.append("taxCalle", formDataNew.fiscalStreet);
    formData.append("taxNumExt", formDataNew.fiscalExtNum);
    formData.append("taxNumInternational", formDataNew.fiscalIntNum);
    formData.append("taxNumColonia", formDataNew.fiscalColony);
    formData.append("taxNumMunicipality", formDataNew.fiscalMunicipality);
    formData.append("taxState", formDataNew.fiscalState);
    formData.append("taxCountry", formDataNew.fiscalCountry);
    formData.append("taxPostalCode", formDataNew.fiscalPostalCode);
    

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/signup-step-4`,
        formData,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      alert("Signup step 4 Successful!");
      console.log(response);
      navigate("/system/pro-register"); // Redirect to dashboard or home page
    } catch (error) {
      alert("Signup failed!");
      console.error(error);
    }
  

  };

  return (
    <div className={styles.conInfoMain}>
      <div className={styles.conInfoConatiner}>
        <div className={styles.conInfoConatiner2}>
          <div className={styles.registerMainHeading}>
            <img src={RegIcn} alt="" className={styles.regIcn} />
            Datos de Contacto
          </div>
          <div className={styles.supRegStepMain}>
            <img src={ContactInfoStep} alt="" className={styles.contactInfoStep} />
          </div>
          <div className={styles.radioText}>Dirección Física:*</div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              Calle:*
              <input type="text" name="street" value={formDataNew.street} onChange={handleChange} className={styles.inputRegister} />
            </div>
            <div className={styles.inputRegisterRight}>
              Núm. Ext.:*
              <input type="text" name="extNum" value={formDataNew.extNum} onChange={handleChange} className={styles.inputRegister} />
            </div>
          </div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              Núm. Int.:
              <input type="text" name="intNum" value={formDataNew.intNum} onChange={handleChange} className={styles.inputRegister} />
            </div>
            <div className={styles.inputRegisterRight}>
              Colonia:*
              <input type="text" name="colony" value={formDataNew.colony} onChange={handleChange} className={styles.inputRegister} />
            </div>
          </div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              Municipio:
              <input type="text" name="municipality" value={formDataNew.municipality} onChange={handleChange} className={styles.inputRegister} />
            </div>
            <div className={styles.inputRegisterRight}>
              Estado:*
              <input type="text" name="state" value={formDataNew.state} onChange={handleChange} className={styles.inputRegister} />
            </div>
          </div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              País:*
              <input type="text" name="country" value={formDataNew.country} onChange={handleChange} className={styles.inputRegister} />
            </div>
            <div className={styles.inputRegisterRight}>
              Código Postal:*
              <input type="text" name="postalCode" value={formDataNew.postalCode} onChange={handleChange} className={styles.inputRegister} />
            </div>
          </div>
          <div className={styles.registerCheckbox}>
            <label className="custom-checkbox">
              <input type="checkbox" 
                 checked={isSameAddress} 
                 onChange={handleCheckboxChange} 
              />
              <span className="checkmark"></span>
            </label>
            <div className={styles.registerCheckboxText}>
              La dirección física es igual que el domicilio fiscal.
            </div>
          </div>
          <div className={styles.radioText}>Domicilio Fiscal:</div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              Calle:
              <input type="text" name="fiscalStreet" value={formDataNew.fiscalStreet} onChange={handleChange} className={styles.inputRegister} />
            </div>
            <div className={styles.inputRegisterRight}>
              Núm. Ext.:
              <input type="text" name="fiscalExtNum" value={formDataNew.fiscalExtNum} onChange={handleChange} className={styles.inputRegister} />
            </div>
          </div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              Núm. Int.:
              <input type="text" name="fiscalIntNum" value={formDataNew.fiscalIntNum} onChange={handleChange} className={styles.inputRegister} />
            </div>
            <div className={styles.inputRegisterRight}>
              Colonia:
              <input type="text" name="fiscalColony" value={formDataNew.fiscalColony} onChange={handleChange} className={styles.inputRegister} />
            </div>
          </div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              Municipio:
              <input type="text" name="fiscalMunicipality" value={formDataNew.fiscalMunicipality} onChange={handleChange} className={styles.inputRegister} />
            </div>
            <div className={styles.inputRegisterRight}>
              Estado:
              <input type="text" name="fiscalState" value={formDataNew.fiscalState} onChange={handleChange} className={styles.inputRegister} />
            </div>
          </div>
          <div className={styles.registerInputMain}>
            <div className={styles.inputRegisterLeft}>
              País:
              <input type="text" name="fiscalCountry" value={formDataNew.fiscalCountry} onChange={handleChange} className={styles.inputRegister} />
            </div>
            <div className={styles.inputRegisterRight}>
              Código Postal:
              <input type="text" name="fiscalPostalCode" value={formDataNew.fiscalPostalCode} onChange={handleChange} className={styles.inputRegister} />
            </div>
          </div>
          <div className={styles.registerInputMain2}>
            <div className={styles.inputRegisterLeft2}>
              Lada Int.:*
              <input type="text" name="intlCode" value={formDataNew.intlCode} onChange={handleChange} className={styles.inputRegister2} />
            </div>
            <div className={styles.inputRegisterRight}>
              Teléfono:*
              <input type="text" name="phone" value={formDataNew.phone} onChange={handleChange} className={styles.inputRegister3} />
            </div>
          </div>
          <div className={styles.registerInputMain3}>
            Correo Electrónico:*
            <input type="text" name="email" value={formDataNew.email} onChange={handleChange} className={styles.inputRegister4} />
          </div>
          <div className={styles.registerInputMain3}>
            Sitio web:*
            <input type="text" name="website" value={formDataNew.website} onChange={handleChange} className={styles.inputRegister4} />
          </div>
          <div className={styles.RegBtnIcnMain}>
            <button className={styles.RegBtnIcn2} onClick={handleSubmit}>
              <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
              <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
              <span>ENVIAR</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
