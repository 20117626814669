import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto'; // Import Chart.js
import styles from "./UserReport.module.css";

const UserReport = () => {
  const trendRef = useRef(null);
  const providerTypesRef = useRef(null);
  const bidsPerMonthRef = useRef(null);
  const openClosedRef = useRef(null);
  const popularBidsRef = useRef(null);

  useEffect(() => {
    // Trend Line Chart
    const trendCtx = trendRef.current.getContext('2d');
    const trendChart = new Chart(trendCtx, {
      type: 'line',
      data: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'Tendencia en Suscripción',
          data: [20,90,80, 60,40 ,45,50,90,20,100,80, 60,40 ,45,30,90,80, 60,10 ,45,50,90], // Replace with actual data
          borderColor: 'rgb(51,102,204)',
          fill: false,
          tension: 0.1
        }]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

    // Provider Types Pie Chart
    const providerTypesCtx = providerTypesRef.current.getContext('2d');
    const providerTypesChart = new Chart(providerTypesCtx, {
      type: 'pie',
      data: {
        datasets: [{
          data: [13.3,48.0, 38.7,], // Replace with actual data
          backgroundColor: ['#50B432', '#ED561B', '#DDDF00'],
      

        }],
      labels: ['Ambos', 'Bienes', 'Servicios'],

      },
      options: {
        responsive: true,
      }
    });

    // Bids Per Month Bar Chart
    const bidsPerMonthCtx = bidsPerMonthRef.current.getContext('2d');
    const bidsPerMonthChart = new Chart(bidsPerMonthCtx, {
      type: 'bar',
      data: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'Cantidad de Licitaciones',
          data: [24, 36, 12, 38, 29, 32, 15, 41, 27, 17, 10, 20], // Replace with actual data
          backgroundColor: '#6096BA'
        }]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

    // Open vs Closed Doughnut Chart
    const openClosedCtx = openClosedRef.current.getContext('2d');
    const openClosedChart = new Chart(openClosedCtx, {
        type: 'pie',
        data: {
          datasets: [{
            data: [62,38], // Replace with actual data
            backgroundColor: ['#0071AF','#A1ACBA'],
        
  
          }],
        labels: ['Cerradas', 'Abiertas' ],
  
        },
        options: {
          responsive: true,
        }
      });

    // Popular Bids Pie Chart
    const popularBidsCtx = popularBidsRef.current.getContext('2d');
    const popularBidsChart = new Chart(popularBidsCtx, {
      type: 'pie',
      data: {
        labels: ['Consulta General', 'Material', 'Sala de Eventos', 'Grabacion', 'Maestro de Ceremonias'],
        datasets: [{
          data: [40, 10, 30, 7,13], // Replace with actual data
          backgroundColor: ['#00A9D7', '#FFA400', '#3A8DDE', '#80BC00', '#6E7C7C']
        }]
      },
      options: {
        responsive: true
      }
    });

    // Cleanup function to destroy charts when the component unmounts
    return () => {
      trendChart.destroy();
      providerTypesChart.destroy();
      bidsPerMonthChart.destroy();
      openClosedChart.destroy();
      popularBidsChart.destroy();
    };
  }, []);

  return (
    <div className={styles.userReportMain}>
      <div className={styles.userReportContainer}>
        <div className={styles.reportHeadingMain}>Reportes</div>
        
        <div className={styles.reportSection}>
            <div className={styles.reportsection1}>
            <div className={styles.reportHeading}>Tendencia en Suscripción de Proveedores</div>
            <canvas ref={trendRef}></canvas>
            </div>
        <div className={styles.reportsection1}>
        <div className={styles.reportHeading}>Tipos de Proveedores</div>
        <canvas ref={providerTypesRef}></canvas>
        </div>
       
        </div>
        
    
        
        <div className={styles.reportSection}>
        <div className={styles.reportsection1}>
        <div className={styles.reportHeading}>Cantidad de Licitaciones por Mes</div>
        <canvas ref={bidsPerMonthRef}></canvas>
        </div>

        <div className={styles.reportsection2}>
            <div className={styles.reportInnerSection}>
            <div className={styles.reportHeading}>Licitaciones Abiertas vs. Cerradas</div>
            <canvas ref={openClosedRef}></canvas>
            </div>
       
        <div className={styles.reportInnerSection}>
        <div className={styles.reportHeading}>Licitaciones más populares</div>
        <canvas ref={popularBidsRef}></canvas>
        </div>
        </div>
    
        </div>
        
        
        
      </div>
    </div>
  );
};

export default UserReport;
