import React, { useState } from "react";
import styles from "./RegisterGou.module.css";
import Work2 from "../../Images/Work2.png";
import RegGoubtn from "../../Images/regGouBtn.png";

const RegisterGou = () => {
  const [formData, setFormData] = useState({
    cantidad: "",
    unidad: "",
    caracteristicas: "",
    especificaciones: "",
    precioUnitario: "",
    precioTotal: "",
    garantias: "",
    soporte: "",
    dia: "",
    mes: "",
    año: "",
    lugarEntrega: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData)
  };


  
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);
  return (
    <div className={styles.registerGouMain}>
      <div className={styles.registerGouContainer}>
        <div className={styles.registerGouContainer2}>
          <div className={styles.homeSection2Head}>
            <div className={styles.workMainHeading}>
              <img src={Work2} alt="" className={styles.Work2Img} />
              Oferta de Licitación para Canapés Gourmet
            </div>
          </div>
          <div className={styles.registerGouSection2Main}>
            <div className={styles.registerInputMain2}>
              <div className={styles.inputRegisterLeft}>
                Cantidad:*
                <input
                  type="text"
                  name="cantidad"
                  value={formData.cantidad}
                  onChange={handleChange}
                  className={styles.inputRegister2}
                />
              </div>
              <div className={styles.inputRegisterRight}>
                Unidad:*
                <input
                  type="text"
                  name="unidad"
                  value={formData.unidad}
                  onChange={handleChange}
                  className={styles.inputRegister2}
                />
              </div>
            </div>
            <div className={styles.regDocmainText}>Características:*</div>
            <textarea
              name="caracteristicas"
              value={formData.caracteristicas}
              onChange={handleChange}
              className={styles.registerTextarea}
            ></textarea>
            <div className={styles.regDocmainText}>Especificaciones Especiales:*</div>
            <textarea
              name="especificaciones"
              value={formData.especificaciones}
              onChange={handleChange}
              className={styles.registerTextarea2}
            ></textarea>
            <div className={styles.registerInputMain2}>
              <div className={styles.inputRegisterLeft}>
                Precio unitario: (Tipo de moneda: MXN o USD)*
                <input
                  type="text"
                  name="precioUnitario"
                  value={formData.precioUnitario}
                  onChange={handleChange}
                  className={styles.inputRegister2}
                />
              </div>
              <div className={styles.inputRegisterRight}>
                Precio Total: (Antes de impuestos)*
                <input
                  type="text"
                  name="precioTotal"
                  value={formData.precioTotal}
                  onChange={handleChange}
                  className={styles.inputRegister2}
                />
              </div>
            </div>
            <div className={styles.regDocmainText}>Garantías:*</div>
            <textarea
              name="garantias"
              value={formData.garantias}
              onChange={handleChange}
              className={styles.registerTextarea3}
            ></textarea>
            <div className={styles.regDocmainText}>Soporte:*</div>
            <textarea
              name="soporte"
              value={formData.soporte}
              onChange={handleChange}
              className={styles.registerTextarea3}
            ></textarea>
            <div className={styles.regDocmainText}>Fecha de entrega del bien o servicio:*</div>
            <div className={styles.selecterMain}>
              <label>
                <select
                  name="dia"
                  value={formData.dia}
                  onChange={handleChange}
                  className={styles.selecterInput}
                >
                  <option value="" disabled>Día:</option>
                  {days.map((d) => (
                    <option key={d} value={d}>{d}</option>
                  ))}
                </select>
              </label>
              <label>
                <select
                  name="mes"
                  value={formData.mes}
                  onChange={handleChange}
                  className={styles.selecterInput2}
                >
                  <option value="" disabled>Mes:</option>
                  {months.map((m, index) => (
                    <option key={index} value={m}>{m}</option>
                  ))}
                </select>
              </label>
              <label>
                <select
                  name="año"
                  value={formData.año}
                  onChange={handleChange}
                  className={styles.selecterInput2}
                >
                  <option value="" disabled>Año:</option>
                  {years.map((y) => (
                    <option key={y} value={y}>{y}</option>
                  ))}
                </select>
              </label>
            </div>
            <div className={styles.registerInputMain}>
              Lugar de entrega:*
              <input
                type="text"
                name="lugarEntrega"
                value={formData.lugarEntrega}
                onChange={handleChange}
                className={styles.inputRegister}
              />
            </div>
            <div className={styles.RegBtnIcnMain}>
              <button className={styles.RegBtnIcn2}>
                <img src={RegGoubtn} alt="" className={styles.RegGoubtn} />
                <span>OFERTAR</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterGou;
