import React from 'react'
import styles from "./Construction.module.css"
import { IoConstructOutline } from "react-icons/io5";

const Construction = () => {
  return (
    <div className={styles.constrMain}>
    <div className={styles.constrContainer}>
        <div className={styles.contrHeadingMain}>
        <IoConstructOutline className={styles.contIcon}/>
        This Website Is Under Construction.

        </div>
    </div>
    </div>
  )
}

export default Construction
