import React from "react";

const Contact = () => {
  const data = [
    {
      name: "Email",
      img: require("../../Images/contact/message.png"),
      text: "adquisiciones@csn.coop",
    },
    {
      name: "Teléfono",
      img: require("../../Images/contact/phone.png"),
      text: "818-305-6900",
    },
    {
      name: "Dirección",
      img: require("../../Images/contact/location.png"),
      text: "Av. República Mexicana No.401 Col. Las Puentes 2 Sec. San Nicolás de los Garza N.L.",
    },
    {
      name: "Sitio Web",
      img: require("../../Images/contact/map.png"),
      text: "https://www.csn.coop/",
    },
  ];
  return (
    <div>
      {/* <Header /> */}
      <div className="relative -z-50 hidden px-[20px] lg:block">
        <div className="absolute left-[20px] top-[50px]">
          <img src={require("../../Images/contact/c1.png")} alt="" />
        </div>
        <div className="absolute left-[20%] top-[50px]">
          <img src={require("../../Images/contact/c2.png")} alt="" />
        </div>
        <div className="absolute right-[20px] top-[50px]">
          <img src={require("../../Images/contact/c4.png")} alt="" />
        </div>
      </div>
      <div className="z-10 mt-20 flex flex-col items-center text-[#71A850] sm:mt-[150px]">
        <div className="flex items-center gap-4 sm:gap-8">
          <img src={require("../../Images/contact/c3.png")} alt="" />
          <h2 className="fontClass text-[36px] font-bold leading-[47.48px]">
            Contacto
          </h2>
        </div>
      </div>
      <div className="relative z-10 mx-auto my-8 max-w-[955px] px-[20px] text-center text-base leading-[23px] text-[#4A4F54] sm:mt-12 sm:text-xl">
        A través de este portal puedes dejarnos tus datos para poder atenderte
        como te mereces. Será un placer apoyarte a resolver todas tus
        inquietudes y acompañarte para lograr todas tus metas.
      </div>

      <div className="mx-auto mt-[10%] flex max-w-[950px] flex-col items-center justify-center gap-[50px] px-[20px] md:flex-row md:items-start md:justify-between">
        <div className="px-5 md:order-2">
          <div className="flex flex-col gap-[20px] md:flex-row">
            <div className="max-w-[254px]">
              <div className="text-[14px] font-bold leading-[17px] text-black">
                Nombre:*
              </div>
              <input
                type="text"
                name=""
                id=""
                className="mt-[10px] w-[254px] rounded-[8px] border-[1px] border-[#D9D9D9]"
              />
            </div>

            <div className="max-w-[254px]">
              <div className="text-[14px] font-bold leading-[17px] text-black">
                Teléfono:*
              </div>
              <input
                type="text"
                name=""
                id=""
                className="mt-[10px] w-[254px] rounded-[8px] border-[1px] border-[#D9D9D9]"
              />
            </div>
          </div>

          <div className="mt-[30px] flex flex-col gap-[20px] md:flex-row">
            <div className="max-w-[254px]">
              <div className="text-[14px] font-bold leading-[17px] text-black">
                Email:*
              </div>
              <input
                type="text"
                name=""
                id=""
                className="mt-[10px] w-[254px] rounded-[8px] border-[1px] border-[#D9D9D9]"
              />
            </div>

            <div className="max-w-[254px]">
              <div className="text-[14px] font-bold leading-[17px] text-black">
                Empresa:*
              </div>
              <input
                type="text"
                name=""
                id=""
                className="mt-[10px] w-[254px] rounded-[8px] border-[1px] border-[#D9D9D9]"
              />
            </div>
          </div>

          <div className="mt-[30px] w-full">
            <div className="text-[14px] font-bold leading-[17px] text-black">
              Mensaje:*
            </div>
            <textarea
              type="text"
              name=""
              id=""
              className="mt-[10px] h-[115px] w-full rounded-[8px] border-[1px] border-[#D9D9D9]"
            />
          </div>
          <div className="mt-[30px] flex items-center justify-center">
            <a
              href="mailto:solve@innomobs.com"
              className="mb-10 mt-4 flex items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 px-3 py-2 text-white hover:bg-green-700"
            >
              <img src={require("../../Assets/buttonIcon.png")} alt="" />
              <span className="font-russo text-lg">ENVIAR</span>
            </a>
          </div>
        </div>

        <ul className="mb-16 flex max-w-xs flex-col gap-6 sm:mb-0">
          {data.map((item) => {
            return (
              <li className="flex flex-col gap-2">
                <div className="fontClass ml-8 self-start text-[16px] font-bold leading-[21px] text-[#4A4F54]">
                  {item.name}
                </div>
                <div className="flex items-center gap-2">
                  <img src={item.img} alt="" className="h-auto w-6 shrink-0" />
                  <div className="fontClass text-[16px] leading-[19px] text-[#71A850]">
                    {item.text}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="-z-50 mt-[-5%] flex items-start justify-between px-[20px]">
        <div>
          <img src={require("../../Images/contact/c5.png")} alt="" />
        </div>
        <div>
          <img src={require("../../Images/contact/c6.png")} alt="" />
        </div>
      </div>

      <div className="mt-[50px]">{/* <Footer /> */}</div>
    </div>
  );
};

export default Contact;
