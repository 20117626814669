import React, { useState } from "react";
import styles from "./RegisterDoc.module.css";
import RegIcn from "../../Images/RegIcn.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import RegDocStep from "../../Images/regDocStepper.png";
import RegDocBtnIcn from "../../Images/regdocbtnIcn.png";
import FileUpIcn from "../../Images/fileupIcn.png";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
const RegisterDoc = () => {
  const navigate = useNavigate();

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top smoothly
  };
  const [fileData01, setFileData01] = useState(null);

  const [fileName01, setFileName01] = useState(
    "FO-ADQ-007 Evaluación de proveedor.*"
  );
  const [fileName1, setFileName1] = useState(
    "Carta de confidencialidad firmada.*"
  );
  const [fileData1, setFileData1] = useState(null);

  const [fileName2, setFileName2] = useState("Acta constitutiva.");
  const [fileData2, setFileData2] = useState(null);

  const [fileName3, setFileName3] = useState(
    "Poder Legal del Representante."
  );
  const [fileData3, setFileData3] = useState(null);

  const [fileName4, setFileName4] = useState(
    "Aviso de privacidad firmado.*"
  );
  const [fileData4, setFileData4] = useState(null);

  const [fileName5, setFileName5] = useState(
    "Firma de aceptación de política anti soborno.*"
  );
  const [fileData5, setFileData5] = useState(null);

  const [fileName6, setFileName6] = useState(
    "Copia de identificación vigente de persona física.*"
  );
  const [fileData6, setFileData6] = useState(null);

  const [fileName7, setFileName7] = useState(
    "Opinión de cumplimiento positiva por el SAT.*"
  );
  const [fileData7, setFileData7] = useState(null);

  const [fileName8, setFileName8] = useState(
    "Constancia de situación fiscal emitida por el SAT.*"
  );
  const [fileData8, setFileData8] = useState(null);

  const [fileName9, setFileName9] = useState(
    "Identificación oficial del proveedor o representante."
  );
  const [fileData9, setFileData9] = useState(null);

  const [fileName10, setFileName10] = useState(
    "Permiso vigente de Seguridad Pública del Estado."
  );
  const [fileData10, setFileData10] = useState(null);

  const handleFileChange = (event, setFileName, setFileData) => {
    const file = event.target.files[0];
    if (file) {
      setFileData(file);
      setFileName(file.name);
    } else {
      setFileName("No file chosen");
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    if (fileData1) formData.append("files", fileData1);
    if (fileData2) formData.append("files", fileData2);
    if (fileData3) formData.append("files", fileData3);
    if (fileData4) formData.append("files", fileData4);
    if (fileData5) formData.append("files", fileData5);
    if (fileData6) formData.append("files", fileData6);
    if (fileData7) formData.append("files", fileData7);
    if (fileData8) formData.append("files", fileData8);
    if (fileData9) formData.append("files", fileData9);
    if (fileData10) formData.append("files", fileData10);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/signup-step-2`,
        formData,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      alert("Signup Successful!");
      console.log(response);
      navigate("/system/sup-register"); // Redirect to dashboard or home page
    } catch (error) {
      alert("Signup failed!");
      console.error(error);
    }
    // Optionally, navigate to the next page
  };

  return (
    <div>
      {/* <Header /> */}
      <div className={styles.regDocMain}>
        <div className={styles.regDocContainer}>
          <div className={styles.regDocContainer2}>
            <div className={styles.registerMainHeading}>
              <img src={RegIcn} alt="" className={styles.regIcn} />
              Documentación para Alta de Proveedor
            </div>

            <div className={styles.regStepMain}>
              <img src={RegDocStep} alt="" className={styles.regDocStep} />
            </div>

            <div className={styles.regDocmainText}>
              En este botón podrás descargar toda la documentación necesaria
              para firmar. Esta documentación la deberás de llenar con tinta
              azul a mano y subirla en esta página.
            </div>

            <div className={styles.RegBtnIcnMain}>
            <a href="/Test.zip" download>
    <button className={styles.RegBtnIcn}>
      <img
        src={RegDocBtnIcn}
        alt=""
        className={styles.RegDocBtnIcn}
      />
      DESCARGAR
    </button>
  </a>
</div>

            <div className={styles.regDocmainText2}>
              Documentación por firmar:*
            </div>

               {/* File Uploads 01 */}
               <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload01"
                accept="application/pdf"  // Restrict to PDF files only
                className={styles.regdocFileUp}
                onChange={(e) =>
                  handleFileChange(e, setFileName01, setFileData01)
                }
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileName01}</span>
              <label htmlFor="fileUpload01" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>


            <div className={styles.regDocmainText2}>
              Para el caso de personas morales deberá ser firmada por el
              representante legal y deberá anexar la documentación que acredite
              la identidad y copia del poder notarial para actuar en
              representación.*
            </div>

            {/* File Uploads */}
            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload1"
                accept="application/pdf"  // Restrict to PDF files only

                className={styles.regdocFileUp}
                onChange={(e) =>
                  handleFileChange(e, setFileName1, setFileData1)
                }
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileName1}</span>
              <label htmlFor="fileUpload1" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload2"
                accept="application/pdf"  // Restrict to PDF files only

                className={styles.regdocFileUp}
                onChange={(e) =>
                  handleFileChange(e, setFileName2, setFileData2)
                }
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileName2}</span>
              <label htmlFor="fileUpload2" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload3"
                accept="application/pdf"  // Restrict to PDF files only

                className={styles.regdocFileUp}
                onChange={(e) =>
                  handleFileChange(e, setFileName3, setFileData3)
                }
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileName3}</span>
              <label htmlFor="fileUpload3" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload4"
                accept="application/pdf"  // Restrict to PDF files only

                className={styles.regdocFileUp}
                onChange={(e) =>
                  handleFileChange(e, setFileName4, setFileData4)
                }
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileName4}</span>
              <label htmlFor="fileUpload4" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload5"
                accept="application/pdf"  // Restrict to PDF files only

                className={styles.regdocFileUp}
                onChange={(e) =>
                  handleFileChange(e, setFileName5, setFileData5)
                }
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileName5}</span>
              <label htmlFor="fileUpload5" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload6"
                accept="application/pdf"  // Restrict to PDF files only

                className={styles.regdocFileUp}
                onChange={(e) =>
                  handleFileChange(e, setFileName6, setFileData6)
                }
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileName6}</span>
              <label htmlFor="fileUpload6" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload7"
                accept="application/pdf"  // Restrict to PDF files only

                className={styles.regdocFileUp}
                onChange={(e) =>
                  handleFileChange(e, setFileName7, setFileData7)
                }
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileName7}</span>
              <label htmlFor="fileUpload7" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload8"
                accept="application/pdf"  // Restrict to PDF files only

                className={styles.regdocFileUp}
                onChange={(e) =>
                  handleFileChange(e, setFileName8, setFileData8)
                }
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileName8}</span>
              <label htmlFor="fileUpload8" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload9"
                accept="application/pdf"  // Restrict to PDF files only

                className={styles.regdocFileUp}
                onChange={(e) =>
                  handleFileChange(e, setFileName9, setFileData9)
                }
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileName9}</span>
              <label htmlFor="fileUpload9" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>

            <div className={styles.fileUploadContainer}>
              <input
                type="file"
                id="fileUpload10"
                accept="application/pdf"  // Restrict to PDF files only

                className={styles.regdocFileUp}
                onChange={(e) =>
                  handleFileChange(e, setFileName10, setFileData10)
                }
                style={{ display: "none" }}
              />
              <span className={styles.fileName}>{fileName10}</span>
              <label htmlFor="fileUpload10" className={styles.customFileUpload}>
                <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                ANEXAR
              </label>
            </div>

            <div className={styles.RegBtnIcnMain}>
              <button className={styles.RegBtnIcn2}     onClick={() => handleSubmit()}>
                <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
                <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
                <span>ENVIAR</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default RegisterDoc;
